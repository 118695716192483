/**
 * Function formatting plural nouns in selected locale
 *
 * @param { number } count - number in which to draw a noun
 * @param { string } locale - selected language
 * @param { [key: string]: string } nouns - plural versions of the noun
 * eg for polish: {many: 'wyników', one: 'wynik', few: 'wyniki'}
 * eg for english/german: {one: 'file', other: 'files'}
 *
 * @return {string} number and formatted plural noun
 */

const formatPluralNouns = (
  count: number,
  locale: string,
  nouns: { [key: string]: string }
): string => {
  const format = new Intl.PluralRules(locale)

  return `${count} ${nouns[format.select(count)]}`
}

export default formatPluralNouns

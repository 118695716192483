import {
  IAvailabilitiesData,
  IHappeningReducer,
  IHappeningSelected
} from './state.types'

const getAvailabilities = (
  happening: IHappeningReducer,
  selected: IHappeningSelected
): IAvailabilitiesData => {
  if (happening.data?.overbookedSlots) {
    const maxNumberOfPeople = happening.data.spaces[0].maxNumberOfPeople
    const howManySlots = Math.ceil(selected.numberOfPlayers / maxNumberOfPeople)

    return happening.availabilities.map((slot, index) => {
      if (slot.isDisabled) {
        return slot
      }

      let isDisabled = false
      for (let i = 0; i < howManySlots; i++) {
        const j = index + i
        const slotExist = happening.availabilities.length > j
        if (!slotExist) {
          isDisabled = true
          break
        }
        const isAnySlotReserved = happening.availabilities[j].spaces.reduce(
          (reserved, space) => reserved || space.reserved,
          false
        )

        isDisabled = slot.isDisabled || isAnySlotReserved

        if (isDisabled) {
          break
        }
      }

      return { ...slot, isDisabled }
    })
  }

  if (happening.data?.calculatePricePerPerson && selected.numberOfPlayers) {
    const availableSlots: IAvailabilitiesData = []
    happening.availabilities.map((slot) => {
      if (slot.capacityLeft >= selected.numberOfPlayers) {
        availableSlots.push(slot)
      }
    })

    return availableSlots
  }

  return happening.availabilities
}

export default getAvailabilities

import {
  IAvailabilities,
  IAvailableSpaceWithDescription,
  IHappeningDataReducer,
  IHappeningSelected
} from './state.types'

const DEFAULT_LANGUAGE = 'pl'

const getSpaces = (
  slot: IAvailabilities | undefined,
  data: IHappeningDataReducer,
  locale: string,
  selected: IHappeningSelected
) =>
  slot?.spaces.map(
    (space): IAvailableSpaceWithDescription => {
      const spaceDescription = data?.spaces.find(
        (_space) => _space.id === space.spaceId
      )
      const localizedDescription =
        spaceDescription?.description.find(
          (desc) => desc.language === locale
        ) ||
        spaceDescription?.description.find(
          (desc) => desc.language === DEFAULT_LANGUAGE
        )

      const available =
        space.capacityLeft === null
          ? space.available
          : data?.overbookedSlots
          ? space.available
          : selected.numberOfPlayers > space.capacityLeft
          ? false
          : space.available

      return {
        ...space,
        available,
        description: localizedDescription?.description,
        title: localizedDescription?.title,
        url: spaceDescription?.url
      }
    }
  ) || []

export default getSpaces

import axios, { CancelTokenSource } from 'axios'

import { catchHttpError, getData } from './../helpers/api'
import { IProductsRequest, IProductsResponse, IProduct } from './products.types'

class ProductsApi {
  private baseUrl: string
  private cancelToken?: CancelTokenSource

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  public cancelCalling() {
    if (this.cancelToken) {
      this.cancelToken.cancel()
      this.cancelToken = undefined
    }
  }

  public getProducts(body: IProductsRequest): Promise<IProduct[]> {
    return new Promise((resolve, reject) => {
      this.cancelToken = axios.CancelToken.source()
      axios
        .get(this.getProductsUrl(body), {
          cancelToken: this.cancelToken.token
        })
        .then(getData)
        .then((response: IProductsResponse) => {
          resolve(response.items)
        })
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  private getProductsUrl(productPayload: IProductsRequest): string {
    return `${this.baseUrl}spaces/${productPayload.space}/products?dateTime=${productPayload.dateTime}`
  }
}

export default ProductsApi

import * as React from 'react'

import {
  GOING_BASKET_ITEMS_NAME,
  GOING_BASKET_USER_DATA_NAME
} from './GoingBasketProvider.constants'
import { IGoingBasketItem, IGoingBag } from './GoingBasketProvider.types'
import {
  getRandomKey,
  isJsonString
} from './../../helpers/operationsWithString'

const GoingBasketProvider = <
  TBasketItem extends IGoingBasketItem,
  TBasketUserData
>() => {
  const context = React.createContext<IGoingBag<
    TBasketItem,
    TBasketUserData
  > | null>(null)

  const Store = ({ children }: { children: React.ReactNode }) => {
    const removeFromBag = (id: number): TBasketItem[] => {
      let basketItems: TBasketItem[] = []

      setState((oldState) => {
        basketItems = oldState.basketItems.filter(
          (product) => product.id !== id
        )

        updateLocalStorage(basketItems)
        return {
          ...oldState,
          basketItems,
          idempotencyKey: getRandomKey()
        }
      })

      return basketItems
    }

    const addToBag = (item: TBasketItem) => {
      setState((oldState) => {
        updateLocalStorage([...oldState.basketItems, item])
        return {
          ...oldState,
          basketItems: [...oldState.basketItems, item],
          idempotencyKey: getRandomKey()
        }
      })
    }

    const updateItem = (item: TBasketItem): TBasketItem[] => {
      let basketItems: TBasketItem[] = []

      setState((oldState) => {
        basketItems = oldState.basketItems

        const indexOfItem = oldState.basketItems.findIndex(
          (basketItem) => basketItem.id === item.id
        )

        if (indexOfItem > -1) {
          basketItems[indexOfItem] = item
        }
        updateLocalStorage(basketItems)

        return {
          ...oldState,
          basketItems,
          idempotencyKey: getRandomKey()
        }
      })

      return basketItems
    }

    const handleShowBacked = () => {
      setState((oldState) => {
        return {
          ...oldState,
          showBasket: !oldState.showBasket
        }
      })
    }

    const handleUserData = (userData: TBasketUserData) => {
      setState((oldState) => {
        updateLocalStorage(oldState.basketItems, userData)

        return {
          ...oldState,
          userData
        }
      })
    }

    const clearBasket = () => {
      setState((oldState) => {
        updateLocalStorage([], {} as TBasketUserData)

        return {
          ...oldState,
          basketItems: [],
          userData: null,
          idempotencyKey: getRandomKey()
        }
      })
    }

    const getSummedPrice = (basketItems: TBasketItem[]): number => {
      if (basketItems && basketItems.length) {
        return basketItems.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.price
        }, 0)
      }
      return 0
    }

    const readLocalStorage = () => {
      try {
        if (isJsonString(localStorage.getItem(GOING_BASKET_ITEMS_NAME) || '')) {
          const basketItems = JSON.parse(
            localStorage.getItem(GOING_BASKET_ITEMS_NAME) || ''
          )
          if (
            isJsonString(
              localStorage.getItem(GOING_BASKET_USER_DATA_NAME) || ''
            )
          ) {
            const userData = JSON.parse(
              localStorage.getItem(GOING_BASKET_USER_DATA_NAME) || ''
            )
            setState((oldState) => {
              return {
                ...oldState,
                basketItems,
                userData,
                idempotencyKey: getRandomKey()
              }
            })
          } else {
            setState((oldState) => {
              return {
                ...oldState,
                basketItems,
                idempotencyKey: getRandomKey()
              }
            })
          }
        }
      } catch (localStorageUnavailable) {}
    }

    const initializeData: IGoingBag<TBasketItem, TBasketUserData> = {
      addToBag,
      basketItems: [],
      clearBasket,
      getSummedPrice,
      handleShowBasket: handleShowBacked,
      handleUserData,
      readLocalStorage,
      removeFromBag,
      showBasket: false,
      userData: null,
      idempotencyKey: '',
      updateItem
    }

    /**
     *  Main going state
     */
    const [basketState, setState] = React.useState(initializeData)

    /**
     * Save with local storage when state has been changed
     */
    const updateLocalStorage = (
      items: TBasketItem[],
      userData?: TBasketUserData | null
    ) => {
      try {
        localStorage.setItem(GOING_BASKET_ITEMS_NAME, JSON.stringify(items))

        if (userData) {
          localStorage.setItem(
            GOING_BASKET_USER_DATA_NAME,
            JSON.stringify(userData)
          )
        }
      } catch (localStorageUnavailable) {}
    }

    React.useEffect(() => {
      readLocalStorage()
    }, [])
    return <context.Provider value={basketState}>{children}</context.Provider>
  }

  return {
    ...context,
    Store,
    clearContext: context
  }
}

export default GoingBasketProvider

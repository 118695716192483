class HttpError extends Error {
  private readonly _status: number

  constructor(message: string, status: number) {
    super(message)

    this._status = status
  }

  get status(): number {
    return this._status
  }
}

export default HttpError

import axios, { CancelTokenSource } from 'axios'
import { Cloudinary, Util } from 'cloudinary-core'

import { catchHttpError, getData } from 'src/helpers/api'
import { ICloudinaryData, ICloudinaryImage } from './cloudinary.types'

const EQUAL = 0
const GREATER = 1
const SMALLER = -1

class CloudinaryApi {
  private cloudName: string
  private cancelToken?: CancelTokenSource

  constructor(cloudName: string) {
    this.cloudName = cloudName
  }

  private orderImage(cloudinaryData: ICloudinaryData): ICloudinaryImage[] {
    return cloudinaryData.resources.sort(
      (image1: ICloudinaryImage, image2: ICloudinaryImage) =>
        image1.context?.custom.order && image2.context?.custom.order
          ? image1.context.custom.order - image2.context.custom.order
          : image1.context?.custom.order
          ? SMALLER
          : image2.context?.custom.order
          ? GREATER
          : EQUAL
    )
  }

  public getImagesByTag(tag: string) {
    return new Promise<string[]>((resolve, reject) => {
      this.cancelToken = axios.CancelToken.source()

      return axios
        .get(this.getUrl(tag), {
          cancelToken: this.cancelToken.token
        })
        .then(getData)
        .then(this.orderImage)
        .then((data: ICloudinaryImage[]) => {
          const imageNames: string[] = data.map(
            (image: ICloudinaryImage) => image.public_id
          )
          resolve(imageNames)
        })
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public cancelAvailabilities() {
    if (this.cancelToken) {
      this.cancelToken.cancel()
      this.cancelToken = undefined
    }
  }

  private getUrl(tag: string): string {
    const options = {
      cloudName: this.cloudName,
      format: 'json',
      type: 'list'
    }
    const scOptions = Util.withSnakeCaseKeys(options)
    const cl = new Cloudinary({})
    const urlPath = cl.url(tag, scOptions)

    return urlPath
  }
}

export default CloudinaryApi

import {
  IHappeningDataReducer,
  IAvailabilitiesData,
  IAvailabilities
} from './state.types'

const DEFAULT_MAX_PEOPLE = 10

const calculateCapacity = (
  availabilities: IAvailabilitiesData,
  data: IHappeningDataReducer,
  selectedSlot?: IAvailabilities
): number => {
  if (data?.overbookedSlots) {
    return data.maxNumberOfPeople
  }

  if (selectedSlot) {
    return selectedSlot.capacityLeft
  }

  const maxCapacityLeft = availabilities.reduce(
    (capacity, slot) =>
      slot.capacityLeft > capacity ? slot.capacityLeft : capacity,
    0
  )

  if (maxCapacityLeft) {
    return maxCapacityLeft
  }

  return data?.maxNumberOfPeople || DEFAULT_MAX_PEOPLE
}

export default calculateCapacity

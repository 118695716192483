import axios, { CancelTokenSource } from 'axios'

import { catchHttpError, getData } from './../helpers/api'
import { ITerm } from './terms.types'

class TermsAPi {
  private baseUrl: string
  private cancelToken?: CancelTokenSource

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  public getTerms(eventSlug: string, runDateSlug: string): Promise<ITerm[]> {
    return new Promise((resolve, reject) => {
      this.cancelToken = axios.CancelToken.source()

      return axios
        .get(this.getTermsUrl(eventSlug, runDateSlug))
        .then(getData)
        .then((response: ITerm[]) => {
          resolve(response)
        })
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public cancelCalling() {
    if (this.cancelToken) {
      this.cancelToken.cancel()
      this.cancelToken = undefined
    }
  }

  private getTermsUrl(eventSlug: string, runDateSlug: string): string {
    return `${this.baseUrl}terms/${eventSlug}/${runDateSlug}`
  }
}

export default TermsAPi

/**
 * Check is correctly json string
 * @param str param
 */
export const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

/**
 * Get random Key
 */
export const getRandomKey = (): string =>
  Math.random().toString(36).substring(2)

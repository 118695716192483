export interface IGoingBasketItem {
  id: number
  price: number
}

export interface IBasketError {
  errorMessage: string
  happeningId: string
  spaceId: string
  dateTime: string
  text: string
}

export interface IGoingBag<TBasketItem, TBasketUserData> {
  removeFromBag: (id: number) => TBasketItem[]
  updateItem: (item: TBasketItem) => TBasketItem[]
  addToBag: (reservation: TBasketItem) => void
  readLocalStorage: () => void
  handleShowBasket: () => void
  clearBasket: () => void
  handleUserData: (userData: TBasketUserData) => void
  getSummedPrice: (basketItems: TBasketItem[]) => number
  basketItems: TBasketItem[]
  userData: TBasketUserData | null
  showBasket: boolean
  idempotencyKey: string
}

export const checkIsInstanceOfBasketError = (
  object: any
): object is IBasketError => {
  return 'errorMessage' in object && 'dateTime' in object
}
